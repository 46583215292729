<template>
  <!--
    The view for the videoDirector-component.
  -->
  <div class="videoDirectorView">
    <VideoDirectorDetails
      :video-recorder-id="videoRecorderId"
      :installation-id="installationId"
      :lane-number="laneNumber"
    />
  </div>
</template>

<script>
export default {
  name: 'VideoDirectorView',
  components: {
    VideoDirectorDetails: () => import('@/components/VideoDirector/VideoDirectorDetails.vue')
  },
  props: {
    videoRecorderId: {
      type: String,
      required: true
    },
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  metaInfo () {
    return {
      title: this.$t('videoDirector')
    }
  }
}
</script>
